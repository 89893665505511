import React, { useState, useCallback } from 'react';
import { Button, Modal } from 'antd';
import { VerticalAlignMiddleOutlined } from '@ant-design/icons';
import Cropper from 'react-easy-crop';
import CustomButton from 'components/Button';

/**
 * Handle the Crop modal feature
 * @param {Boolean} show - Control the modal's display
 * @param {Boolean} canQuit - Weather or not the user can quit without cropping
 * @param {Number} ratio - Frame's ratio
 * @param {String} image - Image's url
 * @param {Callback} onUpdate - Callback to update the crop info
 * @param {Callback} onClose - Callback to close the modal
 */
const Croper = (props) => {
  const [cropSetting, setCropSetting] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const handleClose = (props.canQuit ? props.onClose : () => {});

  const onMirrorPhoto = () => {
    if (!props.image.effects) {
      props.image.effects = {
        isMirrored: true
      };
    } else if (!props.image.effects.isMirrored) {
      props.image.effects.isMirrored = true;
    } else {
      props.image.effects.isMirrored = !props.image.effects.isMirrored;
    }
    setRefresh(!refresh);
  };

  const onCropSubmit = () => {
    props.onUpdate(cropSetting);
    props.onClose();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    if (props.image.effects?.isMirrored) {
      const imageWidth = croppedAreaPixels.width / croppedArea.width * 100;

      croppedAreaPixels.x = imageWidth - croppedAreaPixels.x - croppedAreaPixels.width;
    }
    setCropSetting({ croppedArea, croppedAreaPixels });
  }, [props.image.effects?.isMirrored]);

  return (
    <Modal
      visible={props.show}
      title=''
      onOk={() => handleClose()}
      onCancel={() => handleClose()}
      mask={false}
      style={{
        minHeight: '500px'
      }}
      bodyStyle={{
        height: '500px',
        width: '500px',
        padding: '100px',
      }}
      footer={[
        (
          props.canQuit &&
            <Button key='back' onClick={() => props.onClose()}>
              Annuler
            </Button>
        ),
        <Button
          type='default'
          icon={<VerticalAlignMiddleOutlined rotate={90} />}
          onClick={() => onMirrorPhoto()}
        />,
        <CustomButton
          key='submit'
          type='primary'
          onClick={() => onCropSubmit()}
        >
          Recadrer
        </CustomButton>,
      ]}
    >
      <Cropper
        image={props.image?.photo}
        crop={crop}
        zoom={zoom}
        aspect={props.ratio}
        transform={
          `\
            scale(${props.image.effects?.isMirrored ? -zoom : zoom}, ${zoom})\
            translate(${props.image.effects?.isMirrored ? (-crop.x / zoom) : (crop.x / zoom)}px, ${crop.y / zoom}px)\
            rotate(0deg)\
          `
        }
        onCropChange={(value) => setCrop(value)}
        onCropComplete={onCropComplete}
        onZoomChange={(value) => setZoom(value)}
      />
    </Modal>
  );
};

export default Croper;
