import React, { useState, useEffect, useCallback } from 'react';
import { HomeOutlined, ExpandOutlined, PictureOutlined } from '@ant-design/icons';
import Context from 'controllers/Context';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import ContextTab from './ContextTab';
import FrameTab from './FrameTab';
import PhotoTab from './PhotoTab';
import {
  Wrapper,
  TabWrapper,
  ContentWrapper,
  Tab,
  ActiveTab,
  Icon
} from './LeftPanel.module.css';
import collection_db from './collection_db.json';

const tab = {
  CONTEXT: 'context',
  FRAME: 'frame',
  PHOTO: 'photo'
};

const productType = {
  FRAME: 'frame',
	COLLECTION: 'collection',
  ALBUM: 'album',
  CUSTOM: 'custom'
};

const demoDataType = {
  CONTEXT: 'context',
  FRAME: 'frame',
  PHOTO: 'photo'
};

/**
 * Handle the left panel with the three tabs
 * @param {Object} user - Current user
 * @param {Integer} clientId - Current client id
 * @param {Integer} shootingId - Current shooting id
 */
const LeftPanel = (props) => {
  const [activeTab, setActiveTab] = useState(tab.CONTEXT);
  const [demoDataList, setDemoDataList] = useState([]);
  const [contextList, setContextList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [imageList, setImageList] = useState([]);

  const handleDemoDataAccessSuccess = (result) => {
    setDemoDataList(result);
  };

  const handleDemoDataAccessFailure = (error) => {
    appLogs.logError({ function: 'handleDemoDataAccessFailure', error });
  };

  const getDemoData = useCallback(() => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/editor-demo-data`, {}, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) return handleDemoDataAccessFailure(result);
          return handleDemoDataAccessSuccess(result);
        },
        (error) => handleDemoDataAccessFailure(error)
      );
  }, [props.user.jwt]);

  const handleContextAccessSuccess = (result) => {
    setContextList(result);
  };

  const handleContextAccessFailure = (error) => {
    notification.error('Impossible de récupérer les intérieurs du client', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleContextAccessFailure', component: 'LeftPanel', error });
  };

  const getClientContext = useCallback(() => {
    Context.getByClientId(
      props.clientId,
      handleContextAccessSuccess,
      handleContextAccessFailure
    );
  }, [props.clientId]);

  const handleProductAccessSuccess = (result) => {
		console.log('> collection_db -');
		console.log(collection_db);
		// result.push(collectionProduct);
		result.push(collection_db[0]);
		result.push(collection_db[1]);
		result.push(collection_db[2]);
		result.push(collection_db[3]);
		result.push(collection_db[4]);
		result.push(collection_db[5]);
		console.log(result);
		setProductList(result);
  };

  const handleProductAccessFailure = (error) => {
    notification.error('Impossible d\'accéder à vos produits', 'Veuillez ré-essayer');
    appLogs.logError({ function: 'handleProductAccessFailure', error });
  };

  const getUserProducts = useCallback(() => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/products`, { email: props.user.usr.email }, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) return handleProductAccessFailure(result);
          return handleProductAccessSuccess(result);
        },
        (error) => handleProductAccessFailure(error)
      );
  }, [props.user.usr.email, props.user.jwt]);

  const handleImageListAccessSuccess = (result) => {
    setImageList(result);
  };

  const handleImageListAccessFailure = (error) => {
    notification.error('Impossible de récupérer les photos de la projection', 'Veuillez réessayer');
    appLogs.logError({ function: 'handleImageListAccessFailure', error });
  };

  const getShootingImages = useCallback(() => {
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/images`, { shootingId: props.shootingId }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleImageListAccessFailure(result));
        }
        handleImageListAccessSuccess(result);
      })
      .catch((error) => handleImageListAccessFailure(error));
  }, [props.shootingId, props.user.jwt]);

  useEffect(() => {
    getDemoData();
    getClientContext();
    getShootingImages();
    getUserProducts();
  }, [getClientContext, getDemoData, getShootingImages, getUserProducts]);

  return (
    <div className={Wrapper}>
      <div className={TabWrapper}>
        <div
          className={( activeTab === tab.CONTEXT ? ActiveTab : Tab )}
          onClick={() => setActiveTab(tab.CONTEXT)}
        >
          <HomeOutlined className={Icon} />
          Intérieurs
        </div>
        <div
          className={( activeTab === tab.FRAME ? ActiveTab : Tab )}
          onClick={() => setActiveTab(tab.FRAME)}
        >
          <ExpandOutlined className={Icon} />
          Cadres
        </div>
        <div
          className={( activeTab === tab.PHOTO ? ActiveTab : Tab )}
          onClick={() => setActiveTab(tab.PHOTO)}
        >
          <PictureOutlined className={Icon} />
          Photos
        </div>
      </div>
      <div className={ContentWrapper}>
        {
          activeTab === tab.CONTEXT &&
            <ContextTab
              contextList={contextList}
              demoContextList={demoDataList.filter((data) => data.type === demoDataType.CONTEXT)}
              onButtonClick={props.onButtonClick}
            />
        }
        {
          activeTab === tab.FRAME &&
            <FrameTab
              frameList={productList.filter((product) => product.type === productType.FRAME || product.type === productType.COLLECTION)}
              onButtonClick={props.onButtonClick}
            />
        }
        {
          activeTab === tab.PHOTO &&
            <PhotoTab
              imageList={imageList}
              onButtonClick={props.onButtonClick}
            />
        }
      </div>
    </div>
  );
};

export default LeftPanel;
