import React, { useState, useEffect } from 'react';
import { Spin, Row } from 'antd';
import Button from 'components/Button';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import time from 'utils/time';
import PlancheContact from './PlancheContact';
import ShootingCard from './ShootingCard';
import ContextCard from './ContextCard';
import { LoadingContainer, EmptyContainer } from './ShootingList.module.css';

/**
 * EmptyShootingList
 * @param {Callback} onShootingCreation - Callback to handle shooting creation
 */
const EmptyShootingList = (props) => {
  return (
    <div className={EmptyContainer}>
      <p>Ce client n'a pas encore de projection</p>
      <Button type='primary' onClick={props.onShootingCreation}>Ajouter une projection</Button>
    </div>
  );
};

/**
 * ContextCardList
 * @param {Array} contextList - Context list 
 */
const ContextCardList = (props) => {
	return (
		<Row>
			{
				props.contextList.map((context) => (
					<ContextCard context={context} />
				))
			}
		</Row>
	);
};

/**
 * CardList
 * @param {Array} shootingList - Shooting list
 * @param {Callback} onShootingSelection - Callback to handle shooting selection
 * @param {Callback} onPlancheContactSelection - Callback to handle planche contact selection
 */
const CardList = (props) => {
	return (
		<Row>
			{
				props.shootingList
					.sort((a, b) => new Date(a.projectionDate) < new Date(b.projectionDate) ? 1 : -1)
					.map((shooting, i) => (
					<ShootingCard
						key={i}
						shooting={shooting}
						onShootingSelection={(id) => props.onShootingSelection(id)}
						onPlancheContactSelection={(id) => props.onPlancheContactSelection(id)}
					/>
				))
			}
		</Row>
	);
};

/**
 * ShootingList
 * @param {Object} user - Current user
 * @param {Object} client - Current client
 * @param {Callback} onShootingCreation - Callback to handle shooting creation
 * @param {Callback} onShootingSelection - Callback to handle shooting selection
 */
const ShootingList = (props) => {
  const [shootingId, setShootingId] = useState(0);
	const [contextList, setContextList] = useState([]);
  const [shootingList, setShootingList] = useState([]);
  const [loading, setLoading] = useState(false);
	const shootings = {
		toCome: shootingList.filter((shooting) => !time.isDateInThePast(shooting.projectionDate)),
		past: shootingList.filter((shooting) => time.isDateInThePast(shooting.projectionDate)),
	};
	const contextsToCalibrate = contextList.filter((context) => context.info.size.width === 0 || context.info.size.height === 0);

	const handleContextListAccessSuccess = (result) => {
		setContextList(result);
		console.log('>> contextList', result);
	};

	const handleContextListAccessFailure = (error) => {
		notification.error('Erreur', 'Impossible de récupérer la liste des contextes pour ce client, veuillez réessayer');
		appLogs.logError({ function: 'handleContextListAccessFailure', error });
	};

  const handleShootingListAccessSuccess = (result) => {
    setLoading(false);
    setShootingList(result);
  };

  const handleShootingListAccessFailure = (error) => {
    notification.error('Erreur', 'Impossible de récupérer la liste des shootings pour ce client, veuillez réessayer');
    appLogs.logError({ function: 'handleShootingListAccessFailure', error });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
		request.getDataWithAuth(`${request.API_ENDPOINT_URL}/contexts`, { clientId: props.client.id }, props.user.jwt)
			.then((result) => {
				if (result.statusCode === 400) {
					return (handleContextListAccessFailure(result));
				}
				return (handleContextListAccessSuccess(result));
			})
			.catch((error) => handleContextListAccessFailure(error));
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/shootings`, { clientId: props.client.id }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleShootingListAccessFailure(result));
        }
        return (handleShootingListAccessSuccess(result));
      })
      .catch((error) => handleShootingListAccessFailure(error));
  }, [props.client.id, props.user.jwt]);

  if (loading) {
    return (
      <div className={LoadingContainer}>
        <Spin />
      </div>
    );
  }

  if (shootingList.length === 0) {
    return (<EmptyShootingList onShootingCreation={props.onShootingCreation} />);
  }

  return (
    <div>
      <PlancheContact
        shootingId={shootingId}
        client={props.client}
        onClose={() => setShootingId(0)}
      />
			{
				contextsToCalibrate.length > 0 && (
					<div>
						<h2>Intérieurs à calibrer</h2>
						<ContextCardList contextList={contextsToCalibrate} />
					</div>
				)
			}
			{
				shootings.toCome.length > 0 && (
					<div>
						<h2>Projections à venir</h2>
						<CardList
							shootingList={shootings.toCome}
							onShootingSelection={(id) => props.onShootingSelection(id)}
							onPlancheContactSelection={(id) => setShootingId(id)}
						/>
					</div>
				)
			}
			{
				shootings.past.length > 0 && (
					<div>
						<h2>Projections passées</h2>
						<CardList
							shootingList={shootings.past}
							onShootingSelection={(id) => props.onShootingSelection(id)}
							onPlancheContactSelection={(id) => setShootingId(id)}
						/>
					</div>
				)
			}
    </div>
  );
};

export default ShootingList;
