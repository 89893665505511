import React, { useState, useEffect } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import drawing from 'utils/drawing';
import { Container, Modal } from './HighlightModal.module.css';

/**
 * HighlightModal
 * @param {Boolean} showHighlight - Wether or not to display the highlighted image 
 * @param {String} highlightedImage - Highlighted image
 * @param {Callback} onClose - Callback to handle modal close
 */
 const HighlightModal = (props) => {
   const [imageSize, setImageSize] = useState({});

    useEffect(() => {
      let isMounted = true; // Track if the component is mounted
      const getImageSize = async () => {
        const imageSize = await drawing.getImageSize(props.highlightedImage, drawing.computeCanva());
  
        if (isMounted) {
          setImageSize(imageSize);
        }
      };

      getImageSize();

      return () => {
        isMounted = false; // Cleanup function to set isMounted to false
      };
    }, [ props.highlightedImage ]);

   return (
      <div
        className={Container}
        style={{ display: props.showHighlight ? 'block' : 'none' }}
        onClick={() => props.onClose()}
      >
        <div className={Modal}>
          <ReactImageMagnify
            {...{
                smallImage: {
                  src: props.highlightedImage,
                  isFluidWidth: true,
                },
                largeImage: {
                    src: props.highlightedImage,
                    width: imageSize.width * 3,
                    height: imageSize.height * 3,
                },
                enlargedImagePosition: 'over'
            }}
          />
        </div>
      </div>
   );
};

export default HighlightModal;
