// const actionType = {
//   ACCESS: 'access',
//   CLICK: 'click',
//   EVENT: 'event'
// };

// const actionList = {
//   ACCESS_HOME: {
//     code: 1,
//     type: actionType.ACCESS,
//     description: 'Access home page'
//   },
//   CLICK_INVITE_FRIEND: {
//     code: 2,
//     type: actionType.CLICK,
//     description: 'Click invite-friend button'
//   },
//   CLICK_SEND_INVITE: {
//     code: 3,
//     type: actionType.CLICK,
//     description: 'Click send-invitation button'
//   },
//   EVENT_SEND_INVITE_SUCCESS: {
//     code: 4,
//     type: actionType.EVENT,
//     description: 'Event send-invitation success'
//   },
//   EVENT_SEND_INVITE_FAILURE: {
//     code: 5,
//     type: actionType.EVENT,
//     description: 'Event send-invitation fail'
//   },
//   ACCESS_EDITOR: {
//     code: 6,
//     type: actionType.ACCESS,
//     description: 'Access editor'
//   },
//   CLICK_SAVE: {
//     code: 7,
//     type: actionType.CLICK,
//     description: 'CLick save button'
//   },
//   CLICK_EXPORT: {
//     code: 8,
//     type: actionType.CLICK,
//     description: 'CLick export button'
//   },
//   CLICK_IMPORT_CONTEXT: {
//     code: 9,
//     type: actionType.CLICK,
//     description: 'CLick import-context button'
//   },
//   CLICK_IMPORT_FRAME: {
//     code: 10,
//     type: actionType.CLICK,
//     description: 'CLick import-frame button'
//   },
//   CLICK_IMPORT_PHOTO: {
//     code: 11,
//     type: actionType.CLICK,
//     description: 'CLick import-photo button'
//   },
//   CLICK_DISCONNECT: {
//     code: 12,
//     type: actionType.CLICK,
//     description: 'Click disconnect button'
//   }
// };

const action = {
  ACCESS_HOME: 'ACCESS_HOME',
  CLICK_INVITE_FRIEND: 'CLICK_INVITE_FRIEND',
  CLICK_SEND_INVITE: 'CLICK_SEND_INVITE',
  EVENT_SEND_INVITE_SUCCESS: 'EVENT_SEND_INVITE_SUCCESS',
  EVENT_SEND_INVITE_FAILURE: 'EVENT_SEND_INVITE_FAILURE',
  ACCESS_EDITOR: 'ACCESS_EDITOR',
  CLICK_SAVE: 'CLICK_SAVE',
  CLICK_EXPORT: 'CLICK_EXPORT',
  CLICK_IMPORT_CONTEXT: 'CLICK_IMPORT_CONTEXT',
  CLICK_IMPORT_FRAME: 'CLICK_IMPORT_FRAME',
  CLICK_IMPORT_PHOTO: 'CLICK_IMPORT_PHOTO',
  CLICK_DISCONNECT: 'CLICK_DISCONNECT'
};

const logAction = (user, actionToLog) => {
  // const data = {
  //   who: user.usr?.email,
  //   // when: new Date(),
  //   when: 'new Date()',
  //   where: window.location.href,
  //   // what: actionList[actionToLog]
  //   // what: {}
  // };
  // const data2 = {
  //   who: 'who',
  //   when: 'when',
  //   where: 'where'
  // };

  // console.log(data);
  /*
  request.postDataWithAuth(`${request.API_ENDPOINT_URL}/logs`, data2, user.jwt)
    .then(
      (result) => console.log(result),
      (error) => console.log(error)
    );
  */
};

const actionLogger = { action, logAction };

export default actionLogger;
