/* eslint-disable no-loop-func */
import React, { useState, useEffect, useCallback } from 'react';
import screenfull from 'screenfull';
import { Modal } from 'antd';
import ImageRow from 'components/ImageRow';
import Button from 'components/Button';
import DiaporamaSettings from 'controllers/DiaporamaSettings';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import Diaporama from './Diaporama';
import DefaultModeHeader from './DefaultModeHeader';
import DiaporamaSettingsEditor from './DiaporamaSettingsEditor';
import { SectionTitle } from './DefaultMode.module.css';

/**
 * DefaultModeEmpty
 * @param {Object} user - Current user
 * @param {Integer} shootingId - Current shooting id
 * @param {Callback} onImageCreation - Callback to handle photo upload
 * @param {Callback} onModeSwitch - Callback to handle mode switch
 */
const DefaultModeEmpty = (props) => {
  return (
    <div>
      <Diaporama
        show={false}
        selectedImages={[]}
        imageList={[]}
        likedImages={[]}
      />
      <DefaultModeHeader
        user={props.user}
        shootingId={props.shootingId}
        disabled={true}
        selectedImages={[]}
        onImageLike={() => {}}
        onImageCreation={props.onImageCreation}
        onImageDelete={() => {}}
        onModeSwitch={() => props.onModeSwitch()}
      />
      <br />
      <div style={{ textAlign: 'center' }}>
        <p>Aucune photo pour cette projection</p>
        <p>Cliquez sur 'Ajouter des photos' pour les uploader</p>
      </div>
    </div>
  );
};

/**
 * DefaultMode
 * @param {Object} user - Current user
 * @param {Object} client - Current client
 * @param {Integer} shootingId - Current shooting id
 * @param {Object} shooting - Current shooting
 * @param {Array} selectedImages - List of selected images
 * @param {Callback} onImageUpdate - Callback to handle image update
 * @param {Callback} onImageSelection - Callback to handle image selection
 * @param {Callback} onImageUnselection - Callback to handle image unselection
 * @param {Callback} onModeSwitch - Callback to handle mode switch
 */
const DefaultMode = (props) => {
  const [diaporamaSettings, setDiaporamaSettings] = useState({ settings: {} });
  const [showPhotoNumber, setShowPhotoNumber] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(Date.now());
  const [refresh, setRefresh] = useState(false);

  const handleImageDeleteSuccess = (result) => {
    notification.success('Félicitations !', 'La / les image(s) a / ont bien été supprimée(s)');
  }

  const handleImageDeleteFailure = (error) => {
    notification.error('Impossible de supprimer l\' / les image(s)', 'Veuillez ré-essayer plus tard');
    appLogs.logError({ function: 'handleImageDeleteFailure', error });
  };

  const deleteSelectedImages = () => {
    props.selectedImages.forEach((image) => {
      request.deleteDataWithAuthInline(`${request.API_ENDPOINT_URL}/images/${image.id}`, props.user.jwt, handleImageDeleteSuccess, handleImageDeleteFailure);
      props.shooting.imageList = props.shooting.imageList.filter((item) => item.id !== image.id);
    });
    props.onImageSelection([]);
    setRefresh(!refresh);
  };

  const confirmImageDelete = () => {
    Modal.confirm({
      title: 'Supprimer ce(s) photo(s)',
      content: 'Etes-vous sûr de vouloir supprimer ce(s) photo(s) ?',
      okText: 'Oui, supprimer',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        deleteSelectedImages();
      }
    });
  }

  const isSelected = (id) => {
    for (const image of props.selectedImages) {
      if (image.id === id) {
        return (true);
      }
    }
    return (false);
  };

  const getLikedImages = () => {
    return (props.shooting.imageList.filter((image) => image.isLiked));
  };

  const handleImageSelection = (image) => {
    let tmpArray = props.selectedImages;

    if (!isSelected(image.id)) {
      tmpArray.push(image);
    } else {
      tmpArray = props.selectedImages.filter((elem) => elem.id !== image.id);
    }
    props.onImageSelection(tmpArray);
    setRefresh(!refresh);
  };

  const handleImageLike = () => {
    let i = 0;

    while (i < props.shooting.imageList.length) {
      if (props.selectedImages.find((img) => img.id === props.shooting.imageList[i].id)) {
        props.shooting.imageList[i].isLiked = !props.shooting.imageList[i].isLiked;
        props.onImageUpdate(props.shooting.imageList[i]);
      }
      i += 1;
    }
    props.onImageSelection([]);
    setRefresh(!refresh);
  };

  const handleDiaporamaLike = (image) => {
    let i = 0;

    while (i < props.shooting.imageList.length) {
      if (image.id === props.shooting.imageList[i].id) {
        props.shooting.imageList[i].isLiked = !props.shooting.imageList[i].isLiked;
        props.onImageUpdate(props.shooting.imageList[i]);
      }
      i += 1;
    }
    setRefresh(!refresh);
  };

  const refreshOnImageCreation = (image) => {
    props.shooting.imageList.push(image);
    setRefresh(!refresh);
    setLastRefresh(Date.now());
    if (Date.now() - lastRefresh < 1000) {
      setTimeout(() => setRefresh(!refresh), 1000);
    }
  };

  const likedPhotosAreSelected = () => {
    const nbLiked = props.shooting.imageList.filter((image) => image.isLiked).length;
    const nbLikedSelected = props.selectedImages.filter((image) => image.isLiked).length;

    if (nbLiked === nbLikedSelected) {
      return (true);
    }
    return (false);
  };

  const selectAllLikedPhotos = () => {
    let tmpArray = [];

    if (!likedPhotosAreSelected()) {
      tmpArray = props.shooting.imageList.filter((image) => image.isLiked);
    }
    props.onImageSelection(tmpArray);
    setRefresh(!refresh);
  };

  const compareImageName = (a, b) => {
    if (a.photo < b.photo) {
      return (-1);
    }
    if (a.photo > b.photo) {
      return (1);
    }
    return (0);
  };

  const launchDiaporama = () => {
    const element = document.getElementById('diaporama-container');

    if (screenfull.isEnabled) {
      screenfull.request(element);
    }
  }

  const handleDiaporamaSettingsCreationSuccess = (result) => {
    setDiaporamaSettings(result);
  };

  const handleDiaporaSettingsCreationFailure = (error) => {
    appLogs.logError({ function: 'handleDiaporaSettingsCreationFailure', error });
  };

  const handleDiaporamaSettingsUpdateSuccess = (result) => {
    setDiaporamaSettings(result);
  };

  const handleDiaporamaSettingsUpdateFailure = (error) => {
    appLogs.logError({ function: 'handleDiaporamaSettingsUpdateFailure', error });
  };

  const updateDiaporamaSettings = (newSettings) => {
    const newDiaporamaSettings = { ...diaporamaSettings, settings: newSettings };

    if (!newDiaporamaSettings.id) {
      newDiaporamaSettings.ownerId = props.user.usr.id;
      newDiaporamaSettings.shootingId = props.shootingId;
      DiaporamaSettings.create(
        newDiaporamaSettings,
        (result) => handleDiaporamaSettingsCreationSuccess(result),
        (error) => handleDiaporaSettingsCreationFailure(error)
      );
    } else {
      DiaporamaSettings.update(
        newDiaporamaSettings,
        (result) => handleDiaporamaSettingsUpdateSuccess(result),
        (error) => handleDiaporamaSettingsUpdateFailure(error)
      );
    }
    setDiaporamaSettings(newDiaporamaSettings);
  };

  const handleDiaporamaSettingsAccessSuccess = (result) => {
    if (result.length) {
      setDiaporamaSettings(result[0]);
    }
  };

  const handleDiaporamaSettingsAccessFailure = (error) => {
    console.log('> error : ', error);
  };

  const checkExistingSettings = useCallback(() => {
    DiaporamaSettings.getByShootingId(
      props.shootingId,
      handleDiaporamaSettingsAccessSuccess,
      handleDiaporamaSettingsAccessFailure
    );
  }, [props.shootingId]);

  useEffect(() => {
    document.fullscreenEnabled = true;
    screenfull.onchange(() => {
      if (screenfull.isFullscreen) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
        setShowSettings(false)
        props.onImageUnselection();
      }
    });
    checkExistingSettings();
  }, [checkExistingSettings, props]);

  if (props.shooting.imageList.length === 0) {
    return (
      <DefaultModeEmpty
        user={props.user}
        shootingId={props.shootingId}
        onImageCreation={refreshOnImageCreation}
        onModeSwitch={() => props.onModeSwitch()}
      />
    );
  };

  return (
    <div>
      <Diaporama
        show={isFullScreen}
				client={props.client}
        settings={diaporamaSettings.settings}
        selectedImages={props.selectedImages.sort(compareImageName)}
        imageList={props.shooting.imageList.sort(compareImageName)}
        onLike={(image) => handleDiaporamaLike(image)}
      />
      <DiaporamaSettingsEditor
        show={showSettings}
				client={props.client}
        settings={diaporamaSettings.settings}
        nbImage={props.selectedImages.length ? props.selectedImages.length : props.shooting.imageList.length}
        onUpdateSettings={(newSettings) => updateDiaporamaSettings(newSettings)}
        onLaunch={() => launchDiaporama()}
        onClose={() => setShowSettings(false)}
      />
      <DefaultModeHeader
        user={props.user}
        shootingId={props.shootingId}
        disabled={false}
        selectedImages={props.selectedImages}
        onDiaporamaLaunch={() => setShowSettings(true)}
        onImageLike={handleImageLike}
        onImageCreation={refreshOnImageCreation}
        onImageDelete={confirmImageDelete}
        onshowPhotoNumberChange={(state) => setShowPhotoNumber(state)}
        onModeSwitch={() => props.onModeSwitch()}
      />
      {
        getLikedImages().length !== 0 &&
          <div>
            <h3 className={SectionTitle}>Mentions j'aime</h3>
            <Button
              type='default'
              onClick={() => selectAllLikedPhotos()}
            >
              { !likedPhotosAreSelected() ? <span>Tout sélectionner</span> : <span>Tout dé-sélectionner</span> }
            </Button>
            <ImageRow
              imageList={getLikedImages().sort(compareImageName)}
              showPhotoNumber={showPhotoNumber}
              selectedImages={props.selectedImages}
              onImageSelection={handleImageSelection}
            />
          </div>
      }
      <h3 className={SectionTitle}>Toutes les photos</h3>
      <ImageRow
        imageList={props.shooting.imageList.filter((image) => !image.isLiked).sort(compareImageName)}
        showPhotoNumber={showPhotoNumber}
        selectedImages={props.selectedImages}
        onImageSelection={handleImageSelection}
      />
    </div>
  );
};

export default DefaultMode;
