import React, { useState, useEffect } from 'react';
import { Card, Spin, Row } from 'antd';
import VideoModal from 'components/VideoModal';
import Button from 'components/Button';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import notification from 'utils/notification';
import ClientCard from './ClientCard';
import { EmptyContainer } from './List.module.css';

/**
 * EmptyClientList
 * @param {Callback} onClientAdd - Callback to handle client add
 */
const EmptyClientList = (props) => {
  const [showTutorial, setShowTutorial] = useState(true);

  return (
    <div>
      <VideoModal
        show={showTutorial}
        onboardingStep={2}
        onOk={() => setShowTutorial(false)}
        onCancel={() => setShowTutorial(false)}
      />
      <div className={EmptyContainer}>
        <p>Vous n'avez pas encore de client !</p>
        <Button type='primary' onClick={() => props.onClientAdd()}>Ajouter un client</Button>
      </div>
    </div>
  );
};

/**
 * List
 * @param {Object} user - Current user
 * @param {Callback} onClientAdd - Callback to handle client add
 * @param {Callback} onClientSelection - Callback to handle client selection
 */
const List = (props) => {
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClientListAccessSuccess = (result) => {
    setClientList(result);
    setLoading(false);
  }

  const handleClientListAccessFailure = (error) => {
    const msg = {
      title: 'Erreur',
      description: 'Impossible de récupérer la liste des clients, veuillez réessayer.'
    };

    notification.error(msg.title, msg.description);
    appLogs.logError({ function: 'handleClientListAccessFailure', error });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    request.getDataWithAuth(`${request.API_ENDPOINT_URL}/clients`, { userId: props.user.usr.id }, props.user.jwt)
      .then((result) => {
        if (result.statusCode === 400) {
          return (handleClientListAccessFailure(result));
        }
        return (handleClientListAccessSuccess(result));
      })
      .catch((error) => handleClientListAccessFailure(error));
  }, [props.user.jwt, props.user.usr.id]);

  if (loading) {
    return (<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><Spin /></div>);
  }

  if (clientList.length === 0) {
    return (<EmptyClientList onClientAdd={props.onClientAdd} />);
  }

  return (
    <Card>
      <Row justify='space-between'>
        <h2>Mes clients</h2>
        <Button type='primary' onClick={props.onClientAdd}>Ajouter un client</Button>
      </Row>
      <br />
      <Row>
        {
          clientList.sort((a, b) => a.id > b.id ? -1 : 1).map((client, i) => (
            <ClientCard
              key={i}
              client={client}
              onClientSelection={(id) => props.onClientSelection(id)}
            />
          ))
        }
      </Row>
    </Card>
  );
};

export default List;
