import React, { useState } from 'react';
import { Form, Input, Card, Row, Col, Tag, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import Loading from 'components/Loading';
import notification from 'utils/notification';
import appLogs from 'utils/appLogs';
import request from 'utils/request';
import photo from 'utils/photo';
import { ButtonContainer, ButtonContainerAbsolute, IdentityContainer } from './VisualIdentities.module.css';

/**
 * Handle the visual idendities list
 * @param {Enumerator} state - Weather it is in VIEW, EDIT or ADD
 * @param {Object} user - User
 * @param {Object} profile - Profile of a given user
 * @param {Boolean} loading - Weather or not the data are retrieved yet
 * @param {Callback} setAlert - Callback to display success and error messages
 */
const VisualIdentitities = (props) => {
  const [state, setState] = useState(props.state.VIEW);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState(0);

  const exitEditMode = () => {
    setLoading(false);
    setImage('');
    setState(props.state.VIEW);
  }

  const editIdentity = (id) => {
    setState(props.state.EDIT);
    setEditId(id);
  }

  const handleProfileUpdateSuccess = (data) => {
    const message = {
      DEFAULT: 'Votre profil a bien été mis à jour !'
    };

    exitEditMode();
    props.setAlert({ show: true, type: 'success', message: message.DEFAULT });
    setTimeout(() => props.setAlert({ show: false, type: 'info', message: '' }), 5000);
    props.profile.set(data);
  };

  const handleProfileUpdateFailure = () => {
    const message = {
      DEFAULT: 'Votre profil n\'a pas été mis à jour, veuillez réessayer'
    };

    exitEditMode();
    props.setAlert({ show: true, type: 'error', message: message.DEFAULT });
    setTimeout(() => props.setAlert({ show: false, type: 'info', message: '' }), 5000);
    appLogs.logError({ function: 'handleProfileUpdateFailure' });
  }

  const updateProfile = (tmpProfile) => {
    setLoading(true);
    request.putDataWithAuth(`${request.API_ENDPOINT_URL}/profiles/${props.profile.get().id}`, tmpProfile, props.user.jwt)
      .then(
        (result) => {
          if (result.statusCode === 400) {
            return (handleProfileUpdateFailure());
          }
          return (handleProfileUpdateSuccess(result));
        },
        (error) => handleProfileUpdateFailure()
      );
  };

  const handleFormSubmit = (data, index) => {
    const tmpProfile = props.profile.get();

    data.logo = image !== '' ? image : tmpProfile.visualIdentities[index].logo;
    if (!tmpProfile.visualIdentities) {
      tmpProfile.visualIdentities = [];
    }
    if (index === undefined) {
      tmpProfile.visualIdentities.push(data);
    } else {
      tmpProfile.visualIdentities[index] = data;
    }
    updateProfile(tmpProfile);
  };

  const handleLogoUploadSuccess = (result) => {
    setImage(result.data[0].url);
    setLoading(false);
  };

  const handleLogoUploadFailure = (error) => {
    if (error.typeIsIncorrect) {
      notification.error('Format de photo incorrect', 'Vérifiez que le format est bien .png, .jpg ou .jpeg');
    } else {
      notification.error('Impossible d\'upload la photo', 'Veuillez réessayer');
    }
    setLoading(false);
    appLogs.logError({ function: 'handleLogoUploadFailure', error });
  };

  const uploadLogo = (image) => {
    setLoading(true);
    photo.uploadLogo(image, props.user.jwt, handleLogoUploadSuccess, handleLogoUploadFailure);
  }

  if (props.loading) {
    return (
      <Loading />
    );
  }

  return (
    <div>
      <Row className={ButtonContainer}>
        { state === props.state.VIEW && <Button type='primary' onClick={() => setState(props.state.ADD)}>Ajouter une identité visuelle</Button> }
        { state === props.state.EDIT && <Button type='primary' onClick={() => setState(props.state.ADD)}>Ajouter une identité visuelle</Button> }
      </Row>
      <br />
      {
        state === props.state.ADD &&
          <Form name={`visual-identity-add`} layout='vertical' onFinish={handleFormSubmit}>
            <Row>
              <Card className={IdentityContainer}>
                <div className={ButtonContainerAbsolute}>
                  <Button type='link' onClick={exitEditMode}>Annuler</Button>
                  <Button type='primary' htmlType='submit' loading={loading}>Enregistrer</Button>
                </div>
                <Row>
                  <Col span={8}>
                    <Form.Item label='Nom du studio' name='name'>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label='Logo' name='logo'>
                      <Upload
                        name='avatar'
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        beforeUpload={uploadLogo}
                        onPreview={image.handlePreview}
                      >
                        {
                            image !== '' ?
                              <img src={image} alt='avatar' style={{ width: '100px' }} />
                            :
                              <div>
                                <PlusOutlined />
                                <div className='ant-upload-text'>Upload</div>
                              </div>
                        }
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='Adresse' name='address'>
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='Téléphone' name='phone'>
                      <Input />
                    </Form.Item>
                    <Form.Item label='Email' name='email'>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Row>
          </Form>
      }
      {
        state !== props.state.ADD && props.profile.get().visualIdentities?.map((visualIdentity, index) => (
          <Form
            id={`visual-identity-${index}`}
            name={`visual-identity-${index}`}
            layout='vertical'
            initialValues={{
              name: visualIdentity?.name,
              logo: visualIdentity?.logo,
              address: visualIdentity?.address,
              phone: visualIdentity?.phone,
              email: visualIdentity?.email
            }}
            onFinish={(data) => handleFormSubmit(data, index)}
            key={index}
          >
              <Row>
                <Card className={IdentityContainer}>
                  <div className={ButtonContainerAbsolute}>
                    { visualIdentity.isDefault && <Tag color='#87d068'>principal</Tag> }
                    {
                      state === props.state.EDIT && editId === index ?
                        <>
                          <Button type='link' onClick={exitEditMode}>Annuler</Button>
                          <Button type='primary' htmlType='submit' loading={loading}>Enregistrer</Button>
                        </>
                      :
                        <Button onClick={() => editIdentity(index)}>Modifier</Button>
                    }
                  </div>
                  <Row>
                    <Col span={8}>
                      <Form.Item label='Nom du studio'>
                        {
                          state === props.state.EDIT && editId === index ?
                            <Form.Item name='name'>
                              <Input />
                            </Form.Item>
                          :
                            <p>{ visualIdentity.name }</p>
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label='Logo'>
                        {
                          state === props.state.EDIT && editId === index ?
                            <Form.Item name='logo'>
                              <Upload
                                name='avatar'
                                listType='picture-card'
                                className='avatar-uploader'
                                showUploadList={false}
                                beforeUpload={uploadLogo}
                                onPreview={image.handlePreview}
                              >
                                {
                                  image !== '' ?
                                    <img src={image} alt='avatar' style={{ width: '100px' }} />
                                  :
                                    visualIdentity.logo ?
                                      <img src={visualIdentity.logo} alt='avatar' style={{ width: '100px' }} />
                                    :
                                      <div>
                                        <PlusOutlined />
                                        <div className='ant-upload-text'>Upload</div>
                                      </div>
                                }
                              </Upload>
                            </Form.Item>
                          :
                            visualIdentity.logo ?
                              <img src={visualIdentity.logo} alt='logo' style={{ width: '100px' }} />
                            :
                              <p>Pas de logo</p>
                        }
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label='Adresse'>
                        {
                          state === props.state.EDIT && editId === index ?
                            <Form.Item name='address'>
                              <Input.TextArea rows={4} />
                            </Form.Item>
                          :
                            <p>{ visualIdentity.address?.split('\n').map((line) => <>{ line }<br /></>) }</p>
                        }
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label='Téléphone'>
                        {
                          state === props.state.EDIT && editId === index ?
                            <Form.Item name='phone'>
                              <Input />
                            </Form.Item>
                          :
                            <p>{ visualIdentity.phone }</p>
                        }
                      </Form.Item>
                      <Form.Item label='Email'>
                        {
                          state === props.state.EDIT && editId === index ?
                            <Form.Item name='email'>
                              <Input />
                            </Form.Item>
                          :
                            <p>{ visualIdentity.email }</p>
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Form>
        ))
      }
    </div>
  );
};

export default VisualIdentitities;
